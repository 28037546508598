import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizeGuard } from '@shared/guards';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./features/home/home.module').then((mod) => mod.HomeModule),
  },
  {
    path: 'locations',
    loadChildren: () => import('./features/locations/locations.module').then((mod) => mod.LocationsModule),
    canMatch: [AuthorizeGuard],
  },
  {
    path: 'statistics',
    loadChildren: () => import('./features/statistics/statistics.module').then((mod) => mod.StatisticsModule),
    canMatch: [AuthorizeGuard],
  },
  {
    path: 'projects',
    loadChildren: () => import('./features/projects/projects.module').then((mod) => mod.ProjectsModule),
    canMatch: [AuthorizeGuard],
  },
  {
    path: 'devices',
    loadChildren: () => import('./features/devices/devices.module').then((mod) => mod.DevicesModule),
    canMatch: [AuthorizeGuard],
  },
  {
    path: 'system',
    loadChildren: () => import('./features/system/system-module').then((mod) => mod.SystemModule),
    canMatch: [AuthorizeGuard],
  },
  {
    path: 'my-profile',
    loadChildren: () => import('./features/my-profile/my-profile.module').then((mod) => mod.MyProfileModule),
    //canMatch: [AuthorizeGuard],
  },
  { path: 'support', redirectTo: 'home' },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
