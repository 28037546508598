import { CommonModule, NgOptimizedImage } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { FloatLabelModule } from 'primeng/floatlabel';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { MenuModule } from 'primeng/menu';
import { MeterGroupModule } from 'primeng/metergroup';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import {
  ActionBarComponent,
  ButtonComponent,
  ButtonGroupComponent,
  ContextMenuComponent,
  DropdownComponent,
  FormCalendarComponent,
  FormInputComponent,
  FormTimePickerComponent,
  FormToggleComponent,
  HelperIconComponent,
  NavbarComponent,
  PageHeaderComponent,
  PolygonShapeComponent,
  SpinnerComponent,
  StatusIconComponent,
  SvgComponent,
  TableComponent,
  TimeDayScaleComponent,
} from './components';
import { AllowFromListDirective } from './directives';

const PrimeNGModules = [
  ButtonModule,
  TableModule,
  ProgressSpinnerModule,
  DynamicDialogModule,
  ConfirmDialogModule,
  InputTextModule,
  FloatLabelModule,
  DropdownModule,
  MenuModule,
  PanelMenuModule,
  BadgeModule,
  IconFieldModule,
  InputIconModule,
  SelectButtonModule,
  AccordionModule,
  KeyFilterModule,
  InputSwitchModule,
  CalendarModule,
  TooltipModule,
  CheckboxModule,
  MeterGroupModule,
];

const HallMonitorComponents = [
  ActionBarComponent,
  NavbarComponent,
  PageHeaderComponent,
  SvgComponent,
  TableComponent,
  ButtonComponent,
  SpinnerComponent,
  DropdownComponent,
  ContextMenuComponent,
  ButtonGroupComponent,
  StatusIconComponent,
  FormInputComponent,
  FormToggleComponent,
  FormTimePickerComponent,
  AllowFromListDirective,
  FormCalendarComponent,
  HelperIconComponent,
  TimeDayScaleComponent,
  PolygonShapeComponent,
];

@NgModule({
  declarations: [HallMonitorComponents],
  exports: [CommonModule, ReactiveFormsModule, FormsModule, TranslateModule, HallMonitorComponents, PrimeNGModules],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgOptimizedImage,
    TranslateModule.forChild(),
    PrimeNGModules,
  ],
  providers: [DialogService, ConfirmationService, provideHttpClient(withInterceptorsFromDi())],
})
export class SharedModules {}
