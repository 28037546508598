import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { InputSwitchChangeEvent } from 'primeng/inputswitch';

@Component({
  selector: 'app-form-toggle',
  templateUrl: './form-toggle.component.html',
  styleUrl: './form-toggle.component.scss',
})
export class FormToggleComponent {
  @Input() placeholder = '';
  @Input() control: FormControl = new FormControl();
  @Input() tooltip = '';
  @Input() disabled: boolean = false;

  @Output() valueChange = new EventEmitter<boolean>();

  valueChanged(event: InputSwitchChangeEvent) {
    this.valueChange.emit(event.checked);
  }
}
