import { Component, ElementRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  @ViewChild('content') appContent!: ElementRef;

  constructor(private tr: TranslateService) {
    this.tr.setDefaultLang('da');
    this.tr.use('en');
  }

  toggleSideBar(opened: boolean) {
    this.appContent.nativeElement.style.marginLeft = opened ? '312px' : '92px';
  }
}
