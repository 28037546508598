import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export type TableColumn = { name: string; value: string };
export type TableAction = { label: string; items: { label: string; icon: string; command: () => void }[] };

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
})
export class TableComponent {
  @Input() data: unknown[] = [];
  @Input() columns: TableColumn[] = [];
  @Input() loading = false;
  @Input() rowHover = true;
  @Input() selectable = false;
  @Input() emptyMessage = this.tr.instant('shared.table.emptyMessage');
  @Input() loadingMessage = this.tr.instant('shared.table.loadingMessage');
  @Input() actions: TableAction[] = [];
  @Input() sortField = 'id';
  @Input() expandable = false;
  @Input() expandData: unknown[] = [];
  @Input() expandColumns: TableColumn[] = [];
  @Input() expandEmptyMessage = this.tr.instant('shared.table.emptyMessage');
  @Input() expandLoading = false;
  @Input() expandActions: TableAction[] = [];

  @Output() rowSelected = new EventEmitter();
  @Output() selection = new EventEmitter();

  selectedItems: unknown[] = [];

  constructor(private tr: TranslateService) { }

  onRowContextSelect(event: unknown) {
    this.rowSelected.emit(event);
  }

  onSelection() {
    this.selection.emit(this.selectedItems);
  }
}
