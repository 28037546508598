import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { BaseSettings, SharedStore } from './shared.store';

@Injectable({ providedIn: 'root' })
export class SharedService {
  constructor(private http: HttpService, private sharedService: SharedStore) {}

  initialize(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.get<BaseSettings>('basesettings').subscribe({
        next: (settings) => {
          this.sharedService.base.set(settings);
          resolve();
        },
        error: (error) => reject(error),
      });
    });
  }
}
