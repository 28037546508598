<div class="d-none d-md-block desktop">
  <div class="logo-container">
    <img [ngClass]="{ collapsed: !sidebarOpen }" class="logo" routerLink="/" ngSrc="assets/images/logo_positive.png"
      alt="HallMonitor logo" width="153.6" height="32" priority />
    <img class="logo small" routerLink="/" ngSrc="assets/images/logo_positive_small.png" alt="HallMonitor logo"
      width="32.8" height="32" priority />
  </div>
  <div class="toggleButton btn btn-light" (click)="toggleSidebar()">
    <i class="bi bi-chevron-double{{ sidebarOpen ? '-left' : '-right' }}"></i>
  </div>

  <nav [ngClass]="{ opened: sidebarOpen }">
    <p-panelMenu [model]="authorizedRoutes$()" [multiple]="true">
      <ng-template pTemplate="item" let-item>
        <div class="nav-item" *ngIf="!item.link">
          <app-svg *ngIf="item.icon" [path]="'assets/images/' + item.icon + '.svg'"></app-svg>
          <span>{{ item?.label | translate }}</span>
          <i *ngIf="item.items" class="pi pi-angle-down"></i>
        </div>
        <div class="nav-item" *ngIf="item.link" routerLinkActive="active" routerLink="{{ item.link }}">
          <app-svg *ngIf="item.icon" [path]="'assets/images/' + item.icon + '.svg'"></app-svg>
          <span>{{ item?.label | translate }}</span>
          <i *ngIf="item.items" class="pi pi-angle-down"></i>
        </div>
      </ng-template>
    </p-panelMenu>

    <div class="bottom-nav">
      <p-panelMenu [model]="bottomNavRoutes">
        <ng-template pTemplate="item" let-item>
          <div class="nav-item" routerLinkActive="active" routerLink="{{ item.link }}">
            <app-svg *ngIf="item.icon" [path]="'assets/images/' + item.icon + '.svg'"></app-svg>
            <span>{{ item.label | translate }}</span>
          </div>
        </ng-template>
      </p-panelMenu>
      <app-button [value]="'shared_navbar_logout' | translate" (click)="logOut()" />
      <div *ngIf="user" class="user-info">
        <span><b>{{ user.name }}</b></span>
        <span><i>{{ user.authorized_roles }}</i></span>
        <span>{{ user.organization_name }}</span>
      </div>
    </div>
  </nav>
</div>

<div class="d-md-none mobile navbar">
  <div style="
      padding: 8px 12px;
      width: 100%;
      z-index: 11;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--bs-gray-600);
    ">
    <img class="logo" routerLink="/" src="assets/images/logo_positive.png" alt="HallMonitor logo" height="40" />

    <button class="navbar-toggler" type="button" (click)="toggleMenu()">
      <span class="navbar-toggler-icon"></span>
    </button>
  </div>

  <div class="nav-items" [ngClass]="{ expanded: isMenuVisible }">
    <p-panelMenu [model]="authorizedRoutes$()" [multiple]="true">
      <ng-template pTemplate="item" let-item>
        <div class="nav-item" *ngIf="!item.link">
          <app-svg *ngIf="item.icon" [path]="'assets/images/' + item.icon + '.svg'"></app-svg>
          <span>{{ item?.label | translate }}</span>
          <i *ngIf="item.items" class="pi pi-angle-down"></i>
        </div>
        <div class="nav-item" *ngIf="item.link" routerLinkActive="active" routerLink="{{ item.link }}">
          <app-svg *ngIf="item.icon" [path]="'assets/images/' + item.icon + '.svg'"></app-svg>
          <span>{{ item?.label | translate }}</span>
          <i *ngIf="item.items" class="pi pi-angle-down"></i>
        </div>
      </ng-template>
    </p-panelMenu>

    <div class="bottom-nav">
      <p-panelMenu [model]="bottomNavRoutes">
        <ng-template pTemplate="item" let-item>
          <div class="nav-item" routerLinkActive="active" routerLink="{{ item.link }}">
            <app-svg *ngIf="item.icon" [path]="'assets/images/' + item.icon + '.svg'"></app-svg>
            <span>{{ item?.label | translate }}</span>
          </div>
        </ng-template>
      </p-panelMenu>
      <div *ngIf="user" class="user-info">
        <span>{{ user.name }}</span>
        <span>{{ user.authorized_roles }}</span>
        <span>{{ user.organization_name }}</span>
      </div>
    </div>
  </div>
</div>
