<header class="mb-5">
  <h2>{{ title }}</h2>
  <ng-content></ng-content>
</header>
<div *ngIf="showFilterBar" class="filter-bar d-flex justify-content-between border-bottom">
  <div class="views d-flex align-items-center gap-3 text-uppercase">
    <span>{{ "shared_pageheader_views" | translate }}</span>
    <app-button
      *ngIf="showAllView"
      [value]="'shared_pageheader_all' | translate"
      [outlined]="views.length > 0"
      (click)="resetViews()"
    />
    <app-button-group
      [options]="filterViews.sort()"
      [multiple]="false"
      [selected]="views"
      (selectedChange)="setFilterViews($event)"
    />
  </div>

  <div class="d-flex gap-1">
    <app-dropdown
      *ngIf="filterBy.length > 0"
      [placeholder]="filterByLabel"
      [filter]="true"
      [showLabel]="false"
      [options]="filterBy"
      (changed)="setFilterOrg($event)"
    />
    <p-iconField iconPosition="left">
      <p-inputIcon styleClass="pi pi-search" />
      <input
        type="text"
        pInputText
        [placeholder]="'shared_pageheader_filter' | translate"
        (input)="setFilterString()"
        [(ngModel)]="filterString"
      />
    </p-iconField>
  </div>
</div>
<app-action-bar *ngIf="quickActions" [actions]="quickActions" />
