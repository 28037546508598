<div class="d-flex flex-column">
  <div>
    <label *ngIf="showLabel" for="package">{{ label }}</label> <app-helper-icon *ngIf="tooltip" [text]="tooltip" />
  </div>
  <p-dropdown
    id="package"
    appendTo="body"
    [filter]="filter"
    [showClear]="filter"
    [options]="options"
    [loading]="loading"
    [optionLabel]="'name'"
    [disabled]="disabled"
    [placeholder]="loading ? ('shared.dropdown.loading' | translate) : placeholder"
    [ngClass]="{ 'ng-dirty ng-invalid': invalid }"
    [(ngModel)]="chosenOption"
    (onHide)="onHide()"
    (onChange)="selectOption($event.value)"
  />
  <small *ngIf="invalid">{{ errorMessage }}</small>
</div>
