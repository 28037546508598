<div>
  <label for="{{ placeholder }}">{{ placeholder }}</label>
  <app-helper-icon *ngIf="tooltip" [text]="tooltip" />
</div>
<p-calendar
  [(ngModel)]="date"
  [showIcon]="true"
  [showOnFocus]="readOnly"
  id="{{ placeholder }}"
  [appendTo]="'body'"
  [readonlyInput]="readOnly"
  (onSelect)="valueChanged()"
  [dateFormat]="'yy-mm-dd'"
  dataType="string"
/>

<small *ngIf="control.invalid && control.dirty" class="error-label"> {{ errorLabel }} </small>
