import { Component, Input } from '@angular/core';

export enum IconStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  PENDING = 'Pending',
  DISABLED = 'Disabled',
  IDLE = 'Idle',
}

export type IconStatusType = IconStatus;

@Component({
  selector: 'app-status-icon',
  templateUrl: './status-icon.component.html',
  styleUrl: './status-icon.component.scss',
})
export class StatusIconComponent {
  @Input() value: IconStatusType = IconStatus.ACTIVE;

  public IconStatus = IconStatus;
}
