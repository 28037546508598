import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export type DropdownOption = { name: string; value: any };

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss',
})
export class DropdownComponent implements OnInit {
  @Input() label = '';
  @Input() showLabel = true;
  @Input() loading = false;
  @Input() disabled = false;
  @Input() required = false;
  @Input() filter = false;
  @Input() tooltip?: string;
  @Input() options: DropdownOption[] = [];
  @Input() selectedOptionValue?: string | number;
  @Input() placeholder = this.tr.instant('shared.dropdown.placeholder');
  @Input() errorMessage = this.tr.instant('shared.dropdown.errorMessage');

  @Output() changed = new EventEmitter<DropdownOption>();

  public invalid = false;
  public chosenOption?: DropdownOption;

  constructor(private tr: TranslateService) {}

  ngOnInit() {
    this.chosenOption = this.options.find((option) => option.value === this.selectedOptionValue);
  }

  selectOption(option: DropdownOption) {
    this.invalid = false;
    this.changed.emit(option);
  }

  onHide() {
    this.invalid = this.required && !this.chosenOption;
  }
}
