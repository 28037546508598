import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { LocalStorageService } from './localStorage.service';
import { SharedStore } from './shared.store';

export type CognitoToken = {
  auth_time: number;
  at_hash: string;
  aud: string;
  authorized_roles: string;
  authorized_routes: string;
  country_region_id: string;
  country_region_name: string;
  'cognito:username': string;
  disabled_ui: string;
  email: string;
  event_id: string;
  exp: number;
  hidden_ui: string;
  iat: number;
  iss: string;
  jti: string;
  license_package_id: string;
  license_package_name: string;
  name: string;
  organization_id: string;
  organization_name: string;
  sub: string;
  token_use: string;
  user_language: string;
};

@Injectable({ providedIn: 'root' })
export class TokenService {
  private _token = '';

  constructor(private sharedStore: SharedStore, private localStorage: LocalStorageService) {}

  token = {
    get: () => this._token,
    getFromStorage: () => this.localStorage.get('jwtToken'),
    set: (newToken: string) => {
      this._token = newToken;
      this.sharedStore.user.set(jwtDecode(newToken));
      this.localStorage.set('jwtToken', newToken);
    },
    remove: () => {
      this._token = '';
      this.localStorage.set('jwtToken', '');

    },
  };

  isAuthorized(route?: string) {
    if (!route) return false;
    const authRoutes = this.sharedStore.authorizedRoutes.get().map((r) => r.toLowerCase());
    return authRoutes?.includes(route.toLowerCase()) || authRoutes?.includes((route + '_ro').toLowerCase());
  }

  isLoginExpired() {
    const expiration = this.sharedStore.user.get().exp;
    if (expiration && Date.now() < expiration * 1000 - 5000) {
      return false;
    }
    return true;
  }
}
