import { Injectable } from '@angular/core';
import {
  AreaType,
  BookingProvider,
  CountryRegion,
  CreatedFrom,
  CreatedFromType,
  DepartmentType,
  DeviceHistoryType,
  DeviceState,
  DeviceType,
  FacilityProvider,
  FacilityType,
  LicensePackage,
  MasterActivity,
  OrganizationType,
  RolePackage,
  TimeType,
  TimeZone,
} from '@shared/types';
import { CognitoToken } from './token.service';

export type TokenUser = CognitoToken & {
  isSystemAdmin: boolean;
  nameWithRole: string;
};

export interface BaseSettings {
  areaTypes: AreaType[];
  bookingProviders: BookingProvider[];
  countryRegions: CountryRegion[];
  createdFromTypes: CreatedFromType[];
  createdFroms: CreatedFrom[];
  departmentTypes: DepartmentType[];
  deviceHistoryTypes: DeviceHistoryType[];
  deviceTypes: DeviceType[];
  deviceStates: DeviceState[];
  facilityTypes: FacilityType[];
  hallmonitorActivityTypes: MasterActivity[];
  licensePackages: LicensePackage[];
  organizationTypes: OrganizationType[];
  rolePackages: RolePackage[];
  timeTypes: TimeType[];
  timeZones: TimeZone[];
  facilityProviders: FacilityProvider[];
}

@Injectable({ providedIn: 'root' })
export class SharedStore {
  private _base: BaseSettings = {} as BaseSettings;
  private _user: TokenUser = {} as TokenUser;
  private _authorizedRoutes: string[] = [];

  base = { get: () => this._base, set: (settings: BaseSettings) => (this._base = settings) };

  authorizedRoutes = {
    get: () => this._authorizedRoutes,
    set: (routes: string[]) => (this._authorizedRoutes = routes),
  };

  user = {
    get: () => this._user,
    set: (newUser: TokenUser) => {
      this._user = { ...newUser, isSystemAdmin: newUser.authorized_roles.toLowerCase() === 'system administrator', nameWithRole: newUser.name + ' (' + newUser.authorized_roles + ')' };
      this.authorizedRoutes.set(newUser.authorized_routes?.split(','));
    },
    remove: () => (this._user = {} as TokenUser),
  };

  rolePackages = {
    get: () => this._base.rolePackages,
    set: (rolePackages: []) => (this._base.rolePackages = rolePackages),
  };

  hallmonitorActivityTypes = {
    get: () => this._base.hallmonitorActivityTypes,
    set: (activityTypes: MasterActivity[]) => (this._base.hallmonitorActivityTypes = activityTypes),
  };
}
