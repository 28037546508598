import { Component, EventEmitter, Output, computed } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService, SharedStore } from '@shared/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  @Output() opened = new EventEmitter<boolean>();

  private pageLoaded = false;
  public sidebarOpen = true;
  public user = this.sharedStore.user.get();
  public isMenuVisible = false;
  public authorizedRoutes$ = computed(() => {
    return this.filterRoutesByAuthorized(this.availableRoutes, this.sharedStore.authorizedRoutes.get());
  });
  public availableRoutes = [
    { label: this.tr.instant('navbar_home'), icon: 'icon_dashboard', link: '/dashboard', routeId: 'home' },
    {
      label: this.tr.instant('navbar_locations'),
      icon: 'icon_locations',
      expanded: false,
      routeId: 'locations',
      items: [{ label: this.tr.instant('navbar_facilities'), link: '/locations/facilities', routeId: 'facilities' }],
    },
    {
      label: this.tr.instant('navbar_statistics'),
      icon: 'icon_statistics',
      expanded: false,
      routeId: 'statistics',
      items: [
        { label: this.tr.instant('navbar_summary'), link: '/statistics/summary', routeId: 'summary' },
        { label: this.tr.instant('navbar_insights'), link: '/statistics/insights', routeId: 'insights' },
      ],
    },
    { label: this.tr.instant('navbar_projects'), icon: 'icon_projects', link: '/projects', routeId: 'projects' },
    { label: this.tr.instant('navbar_devices'), icon: 'icon_devices', link: '/devices', routeId: 'devices' },
    {
      label: this.tr.instant('navbar_system'),
      icon: 'icon_system',
      expanded: false,
      routeId: 'system',
      items: [
        { label: this.tr.instant('navbar_users'), link: '/system/users', routeId: 'users' },
        { label: this.tr.instant('navbar_integrations'), link: '/system/integrations', routeId: 'integrations' },
        { label: this.tr.instant('navbar_activities'), link: '/system/activities', routeId: 'activity-mapping' },
        { label: this.tr.instant('navbar_timeModels'), link: '/system/time-models', routeId: 'users' },
        { label: this.tr.instant('navbar_associations'), link: '/system/associations', routeId: 'associations' },
        {
          label: this.tr.instant('navbar_administration'),
          expanded: true,
          routeId: 'SystemAdmin',
          items: [
            {
              label: this.tr.instant('navbar_organizations'),
              link: '/system/admin/organizations',
              routeId: 'organizations',
            },
            { label: this.tr.instant('navbar_roles'), link: '/system/admin/roles', routeId: 'roles' },
            { label: this.tr.instant('navbar_licenses'), link: '/system/admin/licenses', routeId: 'licenses' },
            {
              label: this.tr.instant('navbar_integrations'),
              link: '/system/admin/integrations',
              routeId: 'admin_integrations',
            },
            {
              label: this.tr.instant('navbar_masterActivities'),
              link: '/system/admin/master-activities',
              routeId: 'standard-activity',
            },
          ],
        },
      ],
    },
  ];
  public bottomNavRoutes = [
    { label: this.tr.instant('navbar_support'), icon: 'icon_support', link: '/support' },
    { label: this.tr.instant('navbar_myProfile'), icon: 'icon_profile', link: '/my-profile' },
  ];



  constructor(
    private sharedStore: SharedStore,
    private authService: AuthService,
    private router: Router,
    private tr: TranslateService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.pageLoaded) {
          this.toggleMenu();
        }
        this.pageLoaded = true;
      }
    });
  }

  toggleSidebar() {
    this.sidebarOpen = !this.sidebarOpen;
    this.opened.emit(this.sidebarOpen);
  }

  toggleMenu() {
    this.isMenuVisible = !this.isMenuVisible;
  }

  getRoute(name: string) {
    return this.availableRoutes.find((route) => route.label && route.label.toLowerCase() === name);
  }

  logOut() {
    this.authService.logout();
  }

  private filterRoutesByAuthorized(routes: any[], authorizedRoutes: string[]): any[] {
    return routes.filter((route) => {
      const isAuthorized = authorizedRoutes.includes(route.routeId) || authorizedRoutes.includes(route.routeId + '_ro');
      if (route.items) {
        route.items = this.filterRoutesByAuthorized(route.items, authorizedRoutes);
      }
      return isAuthorized || (route.items && route.items.length > 0);
    });
  }
}
